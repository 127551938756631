// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("channels");

require("bootstrap");
require("jquery");
require("@nathanvda/cocoon");
require("jquery.easing");
require("select2");
require("select2/dist/js/i18n/es");
require("select2/dist/js/i18n/en");
require("selectize");
require("air-datepicker");
require("air-datepicker/src/js/i18n/datepicker.en");
require("air-datepicker/src/js/i18n/datepicker.es");
// require("chart.js");
require("chartkick/chart.js");
require ('chart.js/auto');
import Chart from 'chart.js/auto';
global.Chart = Chart;

window.toastr = require("toastr");
let locale;

import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/backoffice";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

function basicFormListener(forms) {
  if (forms) {
    Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (form.checkValidity() == false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }
}

window.addEventListener("load", function () {
  // Global locale
  locale = $("body").attr("data-locale");

  // Backoffice Theme settings
  import("./theme/sb-admin-2");

  // Jquery initializers
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  // Form initialize Boostra4 form validations
  const productForms = document.querySelectorAll(
    'form.needs-validation[id*="_product"]'
  );
  const userForms = document.querySelectorAll(
    'form.needs-validation[id*="_user"]'
  );
  const customerForms = document.querySelectorAll(
    'form.needs-validation[id*="_customer"]'
  );
  const placeForms = document.querySelectorAll(
    'form.needs-validation[id*="_place"]'
  );
  const mechanismForms = document.querySelectorAll(
    'form.needs-validation[id*="_mechanism"]'
  );
  const serviceForms = document.querySelectorAll(
    'form.needs-validation[id*="_service"]'
  );
  const formForms = document.querySelectorAll(
    'form.needs-validation[id*="form"]'
  );
  const signatureForms = document.querySelectorAll(
    'form.needs-validation[id*="user"]'
  );
  const emailForm = document.querySelectorAll(
    'form.needs-validation[id*="-email"]'
  );
  const reportForm = document.querySelectorAll(
    'form.needs-validation[id*="-report"]'
  );

  basicFormListener(productForms);
  basicFormListener(mechanismForms);
  basicFormListener(userForms);
  basicFormListener(placeForms);
  basicFormListener(serviceForms);
  basicFormListener(customerForms);
  basicFormListener(formForms);
  basicFormListener(signatureForms);
  basicFormListener(emailForm);
  basicFormListener(reportForm);

  // Select2 Core settings
  $.fn.select2.defaults.set("theme", "bootstrap4");
  $.fn.select2.defaults.set("language", locale);
  $.fn.select2.defaults.set("scrollAfterSelect", true);
  $.fn.select2.defaults.set("allowClear", true);
  $.fn.select2.defaults.set("placeholder", "");

  $("select.form-control.select2").select2({
    minimumResultsForSearch: -1
  });
  $("select.selectize").selectize({});

  // Air-datepicker initializers
  $(".air-datepicker").datepicker({
    maxDate: new Date(),
    timeFormat : 'hh:ii',
    timepicker: true,
    language: locale,
  });

  $(".only-datepicker").datepicker({
    maxDate: new Date(),
    timepicker: false,
    language: locale,
  });

  $(".month-datepicker").datepicker({
    minDate: new Date(),
    view: 'months',
    minView: 'months',
    dateFormat: 'yyyy-mm',
    language: locale,
  });

  $(".air-timepicker").datepicker({
    timeFormat: "hh:ii",
    timepicker: true,
    onlyTimepicker: true,
    clearButton: true,
    language: locale,
  });

  $('modal-airpicker').datepicker({
    timeFormat: "hh:ii",
    timepicker: true,
    clearButton: true,
    language: locale,
    autoClose: true,
  });

  $('input[class*="custom-file-input"]').change(function (e) {
    const files = this.files;
    const oldName = document
      .querySelector(`label[for="${this.id}"]`)
      .getAttribute("data-text");
    var fileName = [];
    for (var i = 0; i < files.length; i++) {
      fileName.push(files[i].name);
    }
    if (files.length == 0) {
      fileName = [oldName];
    }
  });

  // Confirmable modal - button delete
  $('a[id*="delete-"]').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const action = $(this).attr("data-action");
    const target = $(this).attr("id").split("-")[1];
    $(`#delete-${target}-modal`)
      .on("shown.bs.modal", function () {
        $(`#delete-${target}-modal form`).attr("action", action);
      })
      .modal();
  });
  $('a[id*="send-"]').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const action = $(this).attr("data-action");
    const target = $(this).attr("id").split("-")[1];
    $(`#send-${target}-modal form`).attr("action", action);
    $(`#send-${target}-modal`)
      .on("shown.bs.modal", function () {
      }).modal()
  });
  $('a[id*="report-"]').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const action = $(this).attr("data-action");
    $(`#report-form-modal form`).attr("action", action);
    $(`#report-form-modal`)
      .on("shown.bs.modal", function () {
      }).modal()
  });

  // Enable/Disable password container
  $('select[class="custom-select"]#user_interface').change(function () {
    const value = $(this).val();
    if (value === "api") {
      $("div#backoffice-container").addClass("d-none");
      $("div#api-container").removeClass("d-none");
      $("input#user_first_name").attr("required", true);
      $("select#user_role").attr("required", false);
      $("input#user_email").attr("required", false);
    } else {
      $("div#backoffice-container").removeClass("d-none");
      $("div#api-container").addClass("d-none");
      $("input#user_first_name").attr("required", false);
      $("select#user_role").attr("required", true);
      $("input#user_email").attr("required", true);
    }
  });

  const { validate, clean, format } = require('rut.js')
  function replaceRut(rut, object) {
    if(rut.length == 2 && rut[1] === '-'){
      object.val(object.val().replace('-',''))
    }else if(rut.length > 1){
      object.val(format(rut))
    }
  }

  function validateRut(rut, object) {
    if(!validate(rut) && rut != ''){
      object.addClass('is-invalid')
      object.parent().find('.invalid-feedback')[0].innerText = 'Rut inválido'
    }else{
      object.removeClass('is-invalid')
    }
  }

  $('input[name*=rut], input[name*=tax_id]').on('change paste keyup',function(){
    replaceRut($(this).val(), $(this))
  })

  $('input[name*=rut], input[name*=tax_id]').focusout(function(){
    validateRut($(this).val(), $(this))
  })
});

// Enabling external user for js.erb files
import JQuery from "jquery";
import SignaturePad from "signature_pad";
window.$ = window.JQuery = JQuery;
window.Sentry = window.Sentry = Sentry;
window.Integrations = window.Integrations = Integrations;
window.process = window.process = process;
window.SignaturePad = window.process = SignaturePad;
// Toaster config
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
